import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaBars } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import { BsXLg } from "react-icons/bs";

// const plans = [
//   {
//     name: "Walletconnect",
//     img: walletconnectImg,
//   },
//   {
//     name: "Walletlink",
//     img: walletlinkImg,
//   },
//   {
//     name: "Fortmatic",
//     img: fortmaticImg,
//   },
// ];

function BasicExample() {
  const [show, setShow] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              🦊
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();

    setWallet(walletResponse.address);
  };
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Write a message in the text-field above.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  useEffect(() => {
    const CurrentWallet = async () => {
      const { address } = await getCurrentWalletConnected();
      setWallet(address);
    };
    CurrentWallet();
    addWalletListener();
    // await window.ethereum.enable();
  }, []);

  return (
    <header className="main-header">
      <Navbar bg="transparent" expand="lg" variant="dark" sticky="top">
        <Navbar.Brand href="/" className="logobg">
          <div className="logo-container">
            <img
              src="../../assets/img/defly/logo.png"
              alt=""
              className="logo-main"
            />
            <div className="anime">
              <img src="../../assets/img/defly/giphylogo2.gif" />
            </div>
            <div className="animee"></div>
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ position: "absolute", right: "-3px", top: "32px" }}
        >
          <span
            onClick={() => setShow(!show)}
            style={{ color: "white", border: "none", outline: "none" }}
          >
            {show ? <FaBars /> : <BsXLg />}
          </span>
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav id="nav-ctrl-s" className="me-auto">
            <Nav.Link href="#">HOME</Nav.Link>
            <Nav.Link href="#gameplay">GAMEPLAY</Nav.Link>
            <Nav.Link href="https://market.deflyball.com/" target="_blank">
              MARKETPLACE
            </Nav.Link>
            {/* <Nav.Link href="#roadmap">ROADMAP</Nav.Link>
            <Nav.Link href="#tokenomics">TOKENOMICS</Nav.Link>
            <Nav.Link href="#audit">AUDIT</Nav.Link> */}
            <Nav.Link
              className="defly-mp-btn"
              href="https://stake.deflyball.com/"
              target="_blank"
            >
              <span>STAKING</span>
            </Nav.Link>
            <Nav.Link
              className="defly-mp-btn active"
              href="https://play.deflyball.com/"
              target="_blank"
            >
              <span>Play</span>
            </Nav.Link>
            {/* <Nav.Link href="#team">TEAM</Nav.Link> */}

            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
        {/* <a href="https://market.deflyball.com/" target="_blank">
          <button
            style={{
              backgroundColor: "#ffc107",
              border: "2px solid white",
              color: "black",
            }}
            className="connect-wallet me-2"
          >connect-wallet
            marketplace
          </button>
        </a> */}

        {walletAddress.length > 0 ? (
          <button className="connect-wallet" onClick={connectWallet}>
            {String(walletAddress).substring(0, 6) +
              "..." +
              String(walletAddress).substring(38)}
          </button>
        ) : (
          <button className="connect-wallet" onClick={connectWallet}>
            Connect Wallet
          </button>
        )}
      </Navbar>
    </header>
  );
}

export default BasicExample;
