import "./App.css";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <div className="App">
      <div className="go-to-top">
        {showButton && (
          <span className="p-3 " onClick={scrollToTop}>
            <img
              src="..\..\assets\img\defly\blue-arrow.png"
              width="60px"
              height="60px"
            />
          </span>
        )}
      </div>
      <Home />
    </div>
  );
}

export default App;
