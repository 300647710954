import { Icon } from "@iconify/react";

const Footer = () => {
  return (
    <>
      <footer>
        <hr style={{ color: "#fcc016" }}></hr>
        <div className="container mt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="text-center">
            <img
              className="footer-logo"
              src="../../assets/img/defly/logo.png"
              alt=""
            />
            <p
              className="text-white mt-1 "
              style={{
                fontFamily: "Quicksand",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "23px",
              }}
            >
              DEFLY BALL OÜ (registry code 16312434)
            </p>
          </div>

          <div className="social-icons">
            {/* <img
                  className="social-icons-single"
                  src="..\..\assets\img\defly\social\facebook.png"
                  alt=""
                /> */}

            <a href="https://t.me/deflyball_official" target="_blank">
              <Icon icon="cib:telegram" color="white" height={48} />
            </a>

            {/* <img
                  className="social-icons-single"
                  src="../../assets/img/defly/social/twitter.png"
                  alt=""
                /> */}
            <a href="https://deflyball.medium.com/" target="_blank">
              <Icon icon="cib:medium-m" color="white" height={48} />
            </a>

            {/* <img
                  className="social-icons-single"
                  src="..\..\assets\img\defly\social\discord.png"
                  alt=""
                /> */}
            <a href="https://twitter.com/DeflyBall" target="_blank">
              <Icon
                icon="ant-design:twitter-circle-filled"
                color="white"
                height={54}
              />
            </a>

            {/* <img
                  className="social-icons-single"
                  src="../../assets/img/defly/social/instagram.png"
                  alt=""
                /> */}
            <a href="https://www.instagram.com/deflyball/" target="_blank">
              <Icon
                icon="akar-icons:instagram-fill"
                color="white"
                height={48}
              />
            </a>
          </div>

          <div
            style={{
              fontFamily: "Quicksand",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "23px",
            }}
            className="col-lg-12 col-12 text-center mt-3 text-white"
          >
            Copyright © 2022{" "}
            <span
              style={{
                fontWeight: 900,
                color: "#fcc016",
              }}
            >
              DeFly Ball{" "}
            </span>
            - All Right Reserved
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
