import React, { useState } from "react";
import { gameplay } from "./data";
import swapimage from "./images/swapimage.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Gameplay = () => {
  // useEffect(() => {
  //   AOS.init({
  //     offset: 300,
  //     once: false,
  //   });
  //   AOS.refresh();
  // }, []);
  const [selected, setSelected] = useState(0);
  const tlength = gameplay.length;
  return (
    <>
      <div
        data-aos="fade-right"
        className="col-sm-6 d-flex align-items-center justify-content-center flex-column"
      >
        <img
          style={{ cursor: "pointer" }}
          className="img-fluid"
          onClick={() => {
            selected === tlength - 1
              ? setSelected(0)
              : setSelected((prev) => prev + 1);
          }}
          src={gameplay[selected].image}
          //   src="../../assets/img/defly/slider-images/slide1.png"
          alt=""
        />
        <div
          style={{ textAlign: "center", marginTop: "15px", cursor: "pointer" }}
        >
          <img
            onClick={() => {
              selected === tlength - 1
                ? setSelected(0)
                : setSelected((prev) => prev + 1);
            }}
            src={swapimage}
            alt="swapimg"
          />
        </div>
      </div>
      <div data-aos="fade-left" className=" col-sm-6">
        <div className="slide-tit">{gameplay[selected].title}</div>
        <p
          className="slide-text"
          style={{ color: "#D8D4D4", fontSize: "15px", lineHeight: "20px" }}
        >
          {gameplay[selected].Description}
        </p>
        <p
          className="slide-text"
          style={{ color: "#D8D4D4", fontSize: "15px", lineHeight: "20px" }}
        >
          {/* {gameplay[selected].Description} */}
        </p>
      </div>
    </>
  );
};

export default Gameplay;
