import React from "react";
import "./hero.css";
import { Icon } from "@iconify/react";

const Hero = () => {
  return (
    <div className="container text-white">
      <div className="coin-tokken-inner-container">
        Official Token Address:
        <span className="copy-text">
          <b>&nbsp; 0x0FE...CbD08A</b>
          <button
            onClick={() =>
              navigator.clipboard.writeText(
                " 0x0FE6A599C280853621A11C12e1a68E6949CbD08A"
              )
            }
          >
            <Icon icon="fa:clipboard" color="white" />
          </button>
        </span>
        <div className="d-flex justify-content-center align-items-center p-2">
          <div className="p-2">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.dextools.io/app/bnb/pair-explorer/0x1cc22cb2855a02016c0bdb301ffa549c5bb23576"
              target="_blank"
            >
              <img
                src="..\assets\img\defly\cointokken\dextools.svg"
                width="35px"
                height="35px"
              />
              <span> DEXTOOLS</span>
            </a>
          </div>
          <div className="p-2">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://pancakeswap.finance/swap?outputCurrency=0x0FE6A599C280853621A11C12e1a68E6949CbD08A"
              target="_blank"
            >
              <img
                src="..\assets\img\defly\cointokken\pancake.png"
                width="35px"
                height="35px"
              />
              <span> PancakeSwap</span>
            </a>
          </div>
          <div className="p-2">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="https://www.coingecko.com/en/coins/deflyball"
              target="_blank"
            >
              <img
                src="..\assets\img\defly\cointokken\coin.webp"
                width="35px"
                height="35px"
              />
              <span>CoinGecko</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
