import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination } from "swiper";
import "bootstrap/dist/js/bootstrap.min.js";
import Header from "../pages/Header";
import Dogcharacterslider from "./sliders/dogcharacterslider";
import Foodpantryslider from "./sliders/foodpantryslider";
import CountUp from "react-countup";
import Footer from "./Footer";
import Audit from "../components/audit/Audit";
import Marketplace from "../components/marketplace/Marketplace";
import Roadmap from "../components/roadmap/Roadmap";
import Gameplay from "../components/gameplay/Gameplay";
import Hero from "../components/Hero/Hero";
import Blog from "../components/blog/Blog";

const Home = () => {
  const partners = [
    {
      src: "../../assets/img/defly/partners/anypad.png",
      link: "https://twitter.com/AnyPadio",
    },
    {
      src: "../../assets/img/defly/partners/astronauts.png",
      link: "https://astronaut.to/",
    },
    {
      src: "../../assets/img/defly/partners/autoventure.png",
      link: "https://autoventure.group/",
    },
    {
      src: "../../assets/img/defly/partners/babylon.png",
      link: "https://babylons.io/",
    },
    {
      src: "../../assets/img/defly/partners/bbsfinance.png",
      link: "https://bbsfinance.be/",
    },
    {
      src: "../../assets/img/defly/partners/cosmo-new.png",
      link: "https://cosmocapital.io/",
    },
    {
      src: "../../assets/img/defly/partners/criterion-big.png",
      link: "https://criterionvc.com/",
    },
    {
      src: "../../assets/img/defly/partners/cryptobudy.png",
      link: "https://cryptobuddy.info/",
    },
    {
      src: "../../assets/img/defly/partners/dreambot.png",
      link: "https://dreamboatcapital.com/",
    },
    {
      src: "../../assets/img/defly/partners/elevaton.png",
      link: "",
      linkdisable: true,
    },
    {
      src: "../../assets/img/defly/partners/excal.png",
      link: "http://excaliburcapital.net/",
    },
    {
      src: "../../assets/img/defly/partners/hashversen.png",
      link: "https://www.hashversecapital.com/",
    },
    {
      src: "../../assets/img/defly/partners/ibc.png",
      link: "https://ibcgroup.io/",
    },
    {
      src: "../../assets/img/defly/partners/kangero.png",
      link: "https://kangaroocapital.io/",
    },
    {
      src: "../../assets/img/defly/partners/kommunitas-blue.png",
      link: "https://kommunitas.net/",
    },
    {
      src: "../../assets/img/defly/partners/lpidao.png",
      link: "https://lpi.finance/",
    },
    {
      src: "../../assets/img/defly/partners/mhventure.png",
      link: "https://www.mhventures.io/",
    },
    {
      src: "../../assets/img/defly/partners/panda.png",
      link: "https://www.pandacapital.io/",
    },
    {
      src: "../../assets/img/defly/partners/pandaa.png",
      link: "https://truepnl.com/",
    },
    {
      src: "../../assets/img/defly/partners/partner1h.png",
      link: "https://hodl.global/",
    },
    {
      src: "../../assets/img/defly/partners/partner3k.png",
      link: "https://twitter.com/KryptoPlayboy",
    },
    {
      src: "../../assets/img/defly/partners/partner4.png",
      link: "https://oddiyana.ventures/",
    },
    {
      src: "../../assets/img/defly/partners/partnerd2.png",
      link: "https://duckdao.io/",
    },
    {
      src: "../../assets/img/defly/partners/polkabridge.png",
      link: "https://polkabridge.org/",
    },
    {
      src: "../../assets/img/defly/partners/r8-capital.png",
      link: "http://www.r8.capital/",
    },
    {
      src: "../../assets/img/defly/partners/rikx.png",
      link: "https://rikxcapital.com/",
    },
    {
      src: "../../assets/img/defly/partners/vespertine-.png",
      link: "https://www.vespertine.capital/",
    },
    {
      src: "../../assets/img/defly/partners/whitelistventure.png",
      link: "https://whitelistventures.com/",
    },
  ];

  return (
    <>
      <Header />

      <section className="hero-section">
        <div className="myowncontainer">
          <img
            className="main-banner"
            src="../../assets/img/defly/main-banner.webp"
            alt=""
          />

          <div className="middle-left">
            <h1 data-aos="fade-down" className="main-tit">
              Defly Ball
            </h1>
            <h2 data-aos="fade-right" className="second-tit text-center">
              Race to Earn
            </h2>
            <button
              data-aos="fade-up"
              className="playbtn btn playbtn2"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              Play
            </button>
            <div class="modal fade " id="myModal">
              <div class="modal-dialog game-trailer">
                <div class="modal-content ">
                  {/* <div class="modal-header">
                    <h4 class="modal-title">Game Trailer</h4>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div> */}

                  <div className="d-flex justify-content-center align-items-center">
                    <video className="game-video" height="100%" controls>
                      <source src="..\assets\img\dog.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <Hero />
            </div>
          </div>
        </div>
      </section>
      <main className="main-bg">
        <section className="dogcharacters" id="dogcharacters">
          <div className="best-wrap">
            <div className="container">
              <div className="row">
                <div className="main-tit-wrap2">
                  <div className="line-before"></div>
                  <h2 className="main-tit2">Dog Characters</h2>
                  <div className="line-after"></div>
                </div>
              </div>
              <div className="slider-wrap">
                <Dogcharacterslider />
              </div>
            </div>
          </div>
        </section>

        <section className="gameplay" id="gameplay">
          <div className="container">
            <div className="row">
              <div className="main-tit-wrap2">
                <div className="line-before"></div>
                <h2 className="main-tit2">Gameplay</h2>
                <div className="line-after"></div>
              </div>
            </div>
            <div className="row">
              <h3 className="thefirst">
                The first ever flyball dogs metaverse racing game on bsc
              </h3>
            </div>
            <div className="for-bg-bd">
              <div className="row">
                <Gameplay />
              </div>
            </div>
          </div>
        </section>

        <section id="food-pantry" className="food-pantry">
          <div className="container">
            <div className="row">
              <div className="main-tit-wrap2">
                <div className="line-before"></div>
                <h2 className="main-tit2">Food Pantry</h2>
                <div className="line-after"></div>
                <h3 className="thefirst">
                  After heavy training, your dog will be tired and hungry. Feed
                  the hunger of dogs by purchasing food from the DeFly Food
                  Pantry.
                </h3>
              </div>
            </div>

            <div className="food-pantry-slider">
              <Foodpantryslider />
            </div>
          </div>
        </section>

        <section id="tokenomics">
          <div className="container">
            <div className="row">
              <div className="main-tit-wrap2">
                <div className="line-before"></div>
                <h2 className="main-tit2">token Metrics</h2>
                <div className="line-after"></div>
              </div>
            </div>
          </div>

          <div className="token-cards-wrapp" id="token-cards-wrapp">
            <div className="container">
              <div className="row">
                <div data-aos="zoom-in" className="col-sm-6 col-md-6 col-lg-4">
                  <div className="token-single-wrap">
                    <div className="circle-wrap"></div>
                    <h3 className="token-item">
                      <CountUp end={2700} />K
                    </h3>
                    <h2 className="token-title">supply at public place</h2>
                  </div>
                </div>
                <div data-aos="zoom-in" className="col-sm-6 col-md-6 col-lg-4">
                  <div className="token-single-wrap">
                    <div className="circle-wrap"></div>
                    <h3 className="token-item">
                      <CountUp end={0} />
                      .10
                    </h3>
                    <h2 className="token-title">public sale price</h2>
                  </div>
                </div>
                <div data-aos="zoom-in" className="col-sm-4 col-md-4 col-lg-4">
                  <div className="token-single-wrap">
                    <div className="circle-wrap"></div>
                    <h3 className="token-item ">
                      <CountUp end={100} />M
                    </h3>
                    <h2 className="token-title">total supply</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="roadmap" className="mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="main-tit-wrap2">
                <div className="line-before"></div>
                <h2 className="main-tit2">Road Map</h2>
                <div className="line-after"></div>
              </div>
            </div>
          </div>
          <Roadmap />
        </section>

        <section id="partners" style={{ marginTop: "100px" }}>
          <div className="container mt-5">
            <div className="row">
              <div className="main-tit3-wrap">
                <h2 className="main-tit3">Partners and Investors</h2>
                <div className="row mt-4">
                  {partners.map((item, index) => {
                    return (
                      <div className="col-lg-3 col-md-4 col-5 mt-3">
                        {item.linkdisable == true ? (
                          <a>
                            <img
                              style={{ width: "110px" }}
                              className="partners-single"
                              src={item.src}
                            />
                          </a>
                        ) : (
                          <a href={item.link} target="_blank">
                            <img
                              style={{ width: "110px" }}
                              className="partners-single"
                              src={item.src}
                            />
                          </a>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* <marquee behavior="" direction="">
                  {partners.length > 0 ? (
                    partners.map((item, index) => {
                      return (
                        <a href={item.link} target="_blank">
                          <img className="partners-single" src={item.src} />
                        </a>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </marquee> */}
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: "100px" }}>
          <div className="container mt-5">
            <div className="main-tit3-wrap">
              <h2 className="main-tit3">Lastest News</h2>
            </div>

            <Blog />
          </div>
        </section>

        <section id="marketplace" style={{ marginTop: "100px" }}>
          <div className="container mt-5">
            <div className="row">
              <div className="main-tit3-wrap">
                <h2 className="main-tit3">
                  <img
                    className="footll"
                    src="../../assets/img/defly/footll.png"
                    alt=""
                  />
                  Marketplace
                  <img
                    className="footrr"
                    src="../../assets/img/defly/footrr.png"
                    alt=""
                  />
                </h2>
              </div>
            </div>
            <div>
              <Marketplace />
            </div>
          </div>

          <div className="container">
            <div className="row"></div>
          </div>
        </section>
        <section id="audit">
          <Audit />
        </section>

        <Footer />
      </main>
    </>
  );
};
export default Home;
