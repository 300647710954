import React from "react";
import "./marketplce.css";
import component12 from "./images/Component12.png";
import component13 from "./images/Component13.png";
import component14 from "./images/Component14.png";
import component15 from "./images/Component15.png";
import component12r from "./images/Component12r.png";
const Marketplace = () => {
  let mpbg1 = document.getElementById("mp-bg-animate1");
  let mpbg2 = document.getElementById("mp-bg-animate2");
  let mpbg3 = document.getElementById("mp-bg-animate3");
  let mpbg4 = document.getElementById("mp-bg-animate4");

  const onMouseActive = () => {
    mpbg1.src = "../../../assets/img/defly/marketplace/Component13r.png";
  };

  const onMouseDeactive = () => {
    mpbg1.src = "../../../assets/img/defly/marketplace/Component12.png";
  };

  const onMouseActive1 = () => {
    mpbg2.src = "../../../assets/img/defly/marketplace/Component12r.png";
  };

  const onMouseDeactive1 = () => {
    mpbg2.src = "../../../assets/img/defly/marketplace/Component13.png";
  };

  const onMouseActive2 = () => {
    mpbg3.src = "../../../assets/img/defly/marketplace/Component14r.png";
  };

  const onMouseDeactive2 = () => {
    mpbg3.src = "../../../assets/img/defly/marketplace/Component14.png";
  };

  const onMouseActive3 = () => {
    mpbg4.src = "../../../assets/img/defly/marketplace/Component15r.png";
  };

  const onMouseDeactive3 = () => {
    mpbg4.src = "../../../assets/img/defly/marketplace/Component15.png";
  };

  // mpbg.addEventListener("mouseover", () => {
  //   mpbg.src =
  //     "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg";
  // });

  // mpbg.addEventListener("mouseleave", () => {
  //   mpbg.src = "..assetsimgdeflymarketplaceComponent12.png";
  // });

  return (
    <section>
      <div className="row main-container">
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div></div>
          <div className="text-center  marketplace-content">
            <h1 className="marketplace-heading">Border Collie</h1>
            <div className="img">
              <img
                className="com12"
                id="mp-bg-animate1"
                src="..\assets\img\defly\marketplace\Component12.png"
                alt="img"
                onMouseEnter={onMouseActive}
                onMouseLeave={onMouseDeactive}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div></div>
          <div className="text-center marketplace-content bottom-img">
            <h1 className="marketplace-heading">australian shepherd</h1>
            <div className="imgone">
              <img
                src="..\assets\img\defly\marketplace\Component13.png"
                id="mp-bg-animate2"
                alt="img"
                onMouseEnter={onMouseActive1}
                onMouseLeave={onMouseDeactive1}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div></div>
          <div className="text-center marketplace-content ">
            <h1 className="marketplace-heading">Bull terrier</h1>
            <div className="imgtwo">
              <img
                src="..\assets\img\defly\marketplace\Component13.png"
                id="mp-bg-animate3"
                alt="img"
                onMouseEnter={onMouseActive2}
                onMouseLeave={onMouseDeactive2}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div></div>
          <div className="text-center marketplace-content  bottom-img">
            <h1 className="marketplace-heading">corgi</h1>
            <div className="imgthree">
              <img
                src="..\assets\img\defly\marketplace\Component15.png"
                id="mp-bg-animate4"
                alt="img"
                onMouseEnter={onMouseActive3}
                onMouseLeave={onMouseDeactive3}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Marketplace;
