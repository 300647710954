import image1 from "./images/Group57.png";
import image2 from "./images/Group58.png";
import image3 from "./images/Group59.png";
import image4 from "./images/Group60.png";
import image5 from "./images/Group61.png";
import image6 from "./images/Group62.png";

export const gameplay = [
  {
    image: image1,
    Description:
      "Play-to-earn is an attractive phenomenon. DeFly Ball offers gameplay where a user can play and earn at the same time. It is a blockchain NFT game built on BSC, with a theme of real-world flyball. In-game, all characters hold a particular position inside as well outside the gaming zone. Now, let’s shift our focus to the gameplay of DeFly Ball.",
    title: "PLAY TO EARN",
  },
  {
    image: image2,
    Description:
      "DeFly Ball is a unique dog sport in that it comprises a team of dogs and handlers. There are four dogs and handlers that complete each heat of a race. In order to win the race  you have to select the dog breed wisely based on its speed  agility  stamina  intelligence  personality  health  etc. DeFly ball has introduced your favorite dog characters in the game based on their different traits which made it highly difficult for you to choose In DeFly Ball  you will experience the different personalities and traits each dog character.Ranging from their extreme intelligence  high energy  agility  stamina  training  passion for jumping and running for long to their fun- loving nature.Although their traits vary  the love for chasing the ball remains the same  making them great competitors for each other  the DeFly Ball game",

    title: "CHARACTER SELECTION",
  },
  {
    image: image3,
    Description:
      "DeFly Ball is the latest FlyBall-centric 3D GameFi built on Binance Smart Chain (BSC) technology. With famous dog breeds, food kits, hurdles jumping and ball catching, arena applauding and so much more to excite its users. DeFly Ball is the coolest game of 3D arts and characters built on blockchain technology and has its own NFTs marketplace as well.At DeFly Ball’s marketplace, you being a user, can buy and sell the in-game characters or assets as valuable NFTs. It also has its own currency $DEFLY native tokens for the ease of users to earn, purchase items, and trade them. $DEFLY is a governance token within the DeFly Ball ecosystem by novel means of trading the in-game collectibles. $DEFLY being an in-game currency is the backbone of the platform",
    title: "MY PROFILE",
  },
  {
    image: image4,
    Description:
      "There are different tournament formats in DeFly Ball but each format involves racing between two teams at a time. Within a team, each dog must run over the jumps, trigger a DeFly ball box release a ball, retrieve the ball and then return over the jumps.  Each race comprises up to five legs, and the team that wins the most legs wins the race. DeFly Ball has an electronic judging system that uses lights and timing sensors used on the start/finish line to determine accurate times, and the winner when both teams complete the course in quick succession. Tournaments are run in divisions based on the fastest speed of the team in a previous competition or a time declared by the team captain. Arranging divisions based on speed enables racing to always be competitive.",
    title: "MATCH FINDING",
  },

  {
    image: image5,
    Description:
      "DeFly ball is a fun, active, and entertaining sport to interact with your own dog, other dog enthusiasts, and their dog in a competitive environment. In DeFly Ball, all the competitors are ranked by examining the results of all the matches played in the tournament. Typically, points are awarded for each match, with competitors ranked based either on a total number of points or average points per match. Usually, each competitor finishes with an equal number of matches, in which case rankings by total points and by average points are equivalent at the end of the tournament. ",
    title: "STANDINGS",
  },
];
