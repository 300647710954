import React from "react";
import "./roadmap.css";
import verticalline from "./images/group2.png";
import verticalline2 from "./images/group2-1.png";
import horizontalline from "./images/horizontal-line.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Roadmap = () => {
  // useEffect(() => {
  //   AOS.init({
  //     offset: 300,
  //     once: false,
  //   });
  //   AOS.refresh();
  // }, []);
  return (
    <>
      <section className="road-map2 ">
        <div className="vertical-img">
          <img src={verticalline} alt="vertical-img" />
        </div>
        <div style={{ marginTop: "35px" }}>
          <div data-aos="fade-right" className="roadmap-bg mt-5 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 2: 2021 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Initial Concept and Idea </li>
                  <li>Research work</li>
                  <li>Website Launch </li>
                </ul>
              </div>
              <div className="col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Litepaper Release</li>
                  <li>Social Media Presence</li>
                </ul>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" className="roadmap-bg2 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 3: 2021 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Smart Contract Development</li>
                  <li> Characters and Graphics Design</li>
                  <li>AMA’s and Marketing</li>
                  <li> Issue White paper v1.0</li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Smart Contract Audit </li>
                  <li>Seed and Private Rounds </li>
                  <li>Airdrop Round 1</li>
                </ul>
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="roadmap-bg2 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 4: 2021 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>PR and Promotions</li>
                  <li>IDO’s and Listing</li>
                  <li>CMC Listing </li>
                  <li>Intro to DEFLY Superdogs</li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Community Building</li>
                  <li> Marketing & Partnership Campaigns</li>
                  <li> Airdrop Round 2</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="road-map2 ">
        <div className="vertical-img">
          <img src={verticalline} alt="vertical-img" />
        </div>
        <div style={{ marginTop: "-10px" }}>
          <div data-aos="fade-right" className="roadmap-bg mt-5 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 1: 2022 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-6">
                <ul className="liststyle">
                  <li>DeFly Token Staking</li>
                  <li>INO’s on Launchpads </li>
                </ul>
              </div>
              <div className="col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Partnership & Collaboration AMA’s </li>
                </ul>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" className="roadmap-bg2 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 2: 2022</h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li> NFT’s Airdrop </li>
                  <li>Mystery box </li>
                  <li>Marketing</li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>CoinGecko Listing </li>
                  <li>Game Elements Reveal</li>
                </ul>
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="roadmap-bg2 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 3: 2022 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li> Introducing New Characters</li>
                  <li> Website Update V 2.0 </li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>NFT Marketplace </li>
                  <li>NFT’s Staking</li>
                </ul>
              </div>
            </div>
          </div>

          <div data-aos="fade-left" className="roadmap-bg2 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 4: 2022 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li> NFTs Staking on DeFlyBall</li>
                  <li>Game trailer</li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>Adding Different Tournaments</li>
                  <li>Game Alpha Version Release </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="road-map2 mt-5">
        <div className="vertical-img">
          <img src={verticalline2} alt="vertical-img" />
        </div>
        <div style={{ marginTop: "30px" }}>
          <div data-aos="fade-right" className="roadmap-bg2 mb-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h2 className="heading ms-3">Quater 1: 2023 </h2>
                <img
                  className="horizontalline"
                  src={horizontalline}
                  alt="img"
                />
              </div>
              <div className="col-lg-6 col-sm-6 col-6">
                <ul className="liststyle">
                  <li>DeFlyBall Game</li>
                </ul>
              </div>
              <div
                style={{ visibility: " hidden" }}
                className="col-lg-6 col-sm-6 col-6 "
              >
                <ul className="liststyle">
                  <li>Adding Different Tournaments</li>
                  <li>Game Alpha Version Release </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Roadmap;
