import React from "react";
import "./audit.css";
import titlebg from "./images/title-bg.png";
import hacken from "./images/hacken.png";
import publicimg from "./images/public.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Audit = () => {
  // useEffect(() => {
  //   AOS.init({
  //     offset: 300,
  //     once: false,
  //   });
  //   AOS.refresh();
  // }, []);
  return (
    <>
      <section className="outer-container">
        <div className="inner-container">
          <img src={titlebg} alt="titkebg" />
          <h2 className="ms-3">Audit</h2>
        </div>
        <div className="audit-img mt-4 mx-auto ">
          <a
            data-aos="fade-right"
            href="https://www.rdauditors.com/wp-content/uploads/2021/10/DeFly-Ball-Smart-Contract-Security-Report.pdf"
            target="_blank"
          >
            <img src={publicimg} alt="hacken" />
          </a>
          <a
            data-aos="fade-left"
            href="https://hacken.io/wp-content/uploads/2021/11/DeFlyBall_24112021SCAudit_Report-1.pdf"
            target="_blank"
          >
            <img src={hacken} alt="public" />
          </a>
        </div>
      </section>
    </>
  );
};

export default Audit;
