import React from "react";
import "./blog.css";

const Blog = () => {
  return (
    <>
      {" "}
      <div className="row mt-5 card-content">
        <div className="col-lg-4 col-md-8 col-12 mt-4">
          <div class="card blog-card">
            <img
              class="card-img-top"
              src="https://miro.medium.com/max/700/1*fwaBoPkY8hy1npxAdfBLEw.jpeg"
              alt="Card image"
              style={{ width: "100%" }}
            />
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <h4 class="card-title">
                <b>THE CORGI BREED</b>
              </h4>
              <p class="card-text text-center">
                Corgi has a personality that’s larger than its life, despite
                their little size. Though like other dogs, its personality
                differs from one dog to the next, it’s generally a happy breed
                that enjoys playing...
              </p>
              <a
                href="https://deflyball.medium.com/why-buy-skooty-the-corgi-breed-defly-ball-super-racing-dogs-fireside-discussion-d3b858306314"
                target="_blank"
                class="btn btn-warning text-white "
              >
                <b>Read More</b>
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-8 col-12 mt-4">
          <div class="card blog-card card2">
            <img
              class="card-img-top "
              src="https://miro.medium.com/max/700/1*0r5xdRX31uY5MrZXygXisA.jpeg"
              alt="Card image"
              style={{ width: "100%" }}
            />
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <h4 class="card-title">
                <b>MEGA MYSTERY BOX</b>
              </h4>
              <p class="card-text text-center">
                In a world of games, you are always keen to gain your favorite
                character with its super traits but have to wait till meeting
                the requirements. Isn’t it good if you get it in a surprising
                mystery box...
              </p>
              <a
                href="https://deflyball.medium.com/defly-ball-mega-mystery-box-opportunity-to-get-your-favorite-characters-6574015fc85d"
                target="_blank"
                class="btn btn-warning text-white "
              >
                <b>Read More</b>
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-8 col-12 mt-4">
          <div class="card blog-card">
            <img
              class="card-img-top mt"
              src="https://miro.medium.com/max/700/1*pyHzF9GB60DUaO6Ao83_KQ.jpeg"
              alt="Card image"
              style={{ width: "100%" }}
            />
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <h4 class="card-title">
                <b>FREE 300 NFTs</b>
              </h4>
              <p class="card-text text-center mt-2">
                Today, the DeFly Ball team’s flying in the air. We got an
                overwhelming response from our beloved supportive community on
                the 300 free NFTs Airdrop campaign. It went beyond our
                expectations...
              </p>
              <a
                href="https://deflyball.medium.com/defly-balls-free-300-nfts-airdropped-to-your-wallets-now-87859588c4cd"
                target="_blank"
                class="btn btn-warning text-white "
              >
                <b>Read More</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
