import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Foodpantryslider = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div>
        <img
          className="slider-imgs"
          src="../../../assets/img/defly/foodpantery/slide1.png"
          alt=""
        />
      </div>
      <div>
        <img
          className="slider-imgs"
          src="../../../assets/img/defly/foodpantery/slide2.png"
          alt=""
        />
      </div>
      <div>
        <img
          className="slider-imgs"
          src="../../../assets/img/defly/foodpantery/slide3.png"
          alt=""
        />
      </div>
      <div>
        <img
          className="slider-imgs"
          src="../../../assets/img/defly/foodpantery/slide4.png"
          alt=""
        />
      </div>
      <div>
        <img
          className="slider-imgs"
          src="../../../assets/img/defly/foodpantery/slide1.png"
          alt=""
        />
      </div>
      <div>
        <img
          className="slider-imgs"
          src="../../../assets/img/defly/foodpantery/slide2.png"
          alt=""
        />
      </div>
    </Slider>
  );
};
export default Foodpantryslider;
